import React from "react"
import { Link } from "gatsby"
import Seo from "../components/SEO"
import { StaticImage } from "gatsby-plugin-image"
import paths from "../constants/paths"
import styled from "styled-components"
import PictureWithFlowers from "../components/Decorators/PictureWithFlowers"
import WingedContent from "../components/Decorators/WingedContent"

const Container = styled.div``

const HiImBetty = styled.article`
  background: var(--brand-color-off-white);

  @media (min-width: 1440px) {
    h2 {
      max-width: 900px;
    }
    p, ul {
      margin-left: 0;
    }
  }
`

const bettyImage = <StaticImage alt="Seattle adventure and wedding photography" src="../images/theme/seattle-adventure-wedding-photographer.jpg" width={500} />;

const FeaturedIn = styled.article`
  background: var(--brand-color-light-blue);
  text-align: center;
  
  p {
    text-align: center;
  }
`;

const FeaturedInHeader = styled.div`
  margin: 0 auto;
  position: relative;
  z-index: 1;
  
  h2 {
    margin-top: 0;
    padding: 2rem;
  }

  .rightWing {
    right: -40px;
    top: 10px;

    @media (min-width: 768px) {
      right: -70px;
      top: 0px;
    }

    @media (min-width: 1200px) {
      right: -110px;
      top: 10px;
    }
  }

  @media (min-width: 768px) {
    h2 {
      padding: 2rem;
      display: inline-block;
    }
  }
`

const ListWrapper = styled.div`
  ul {
    list-style: none;
    max-width: 100%;
    margin-bottom: 3rem;
  }

  li {
    text-align: left;
    font-family: var(--header-font-families);
    text-transform: uppercase;
    text-align: center;
    line-height: 2;
    font-weight: bold;
    margin-left: 0;
  }

  @media (min-width: 425px) {
    ul {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
    }
  }

  @media (min-width: 1024px) {
    ul {
      columns: 3;
      -webkit-columns: 3;
      -moz-columns: 3;
    }
  }

  @media (min-width: 1440px) {
    ul {
      max-width: 1300px;
    }
  }
`

const Bird = styled.div`
  text-align: center;
  margin: -30px 40px -20px 0;

  .gatsby-image-wrapper, img {
    height: 90px;
  }
`;

const FunFacts = styled.article`
  background: var(--brand-color-off-white);
  position: relative;
  z-index: 1;

  header {
    margin: 0 auto;
    text-align: center;

    h2 {
      padding: 2rem;
    }

    .gatsby-image-wrapper {
      transform: rotate(45deg);
    }

    .rightWing {
      right: -40px;
      top: 0;

      @media (min-width: 768px) {
        right: -70px;
        top: -10px;
      }

      @media (min-width: 1200px) {
        right: -110px;
        top: -20px;
      }

      @media (min-width: 1440px) {
      }
    }

    @media (min-width: 768px) {
      h2 {
        padding: 2rem;
        display: inline-block;
      }
    }
  }

  ul {
    list-style: none;
    max-width: 100%;
    margin-bottom: 3rem;
    margin-top: 0;
  }

  li {
    padding: 1rem 0;
    margin-left: 0;
    position: relative;
    text-align: center;
    
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      border-bottom: 1px solid var(--brand-color-light-blue);
      display: block;
    }

    &:last-child:after {
      content: none;
    }
  }

  @media (min-width: 768px) {
    ul {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
    }
    
    li {
      text-align: left;
    }
  }

  @media (min-width: 1024px) {
    ul {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }
`

const MyPhilosophy = styled.article`
  .gatsby-image-wrapper {
    margin: -20px 0;
  }
  p:last-child{
    text-align: center;
  }
`

const FlowerWrapper = styled.div`
  z-index: 1;

  @media (max-width: 767px) {
    width: 100%;
    overflow: hidden;
  }

  > div {
    transform: scale(.6);
    margin-top: -100px;
    margin-bottom: -100px;

    @media (min-width: 425px) {
      transform: scale(.8);
      margin-top: -50px;
      margin-bottom: -50px;

      > div {
        width: 360px;
      }
    }

    @media (min-width: 768px) {
      transform: scale(.55);
      margin-top: -130px;
      margin-left: -30px;
    }

    @media (min-width: 1024px) {
      transform: scale(.8);
      margin-top: -110px;
    }

    @media (min-width: 1400px) {
      transform: scale(1);
      margin-top: -30px;
    }
  }

  @media (min-width: 1400px) {
    text-align: right;
  }
`

const MoreAboutMe = styled.article`
  h2 {
    text-align: center;
  }
`

const MoreAboutMeImage = styled.div`
  margin: 0 auto;
  .gatsby-image-wrapper {
    margin: 1rem auto;

    .gatsby-image-wrapper, img {
      max-height: 95vh;
      max-width: 1200px;
    }
  }
`

const CenterImageWrapper = styled.div`
  margin: 1rem auto;
  text-align: center;
`

const About = ({ location }) => {
  return (
    <Container>
      <Seo title={paths.about.title} location={location} />
      <HiImBetty>
        <div className="pure-g">
          <div className="pure-u-1-3 pure-u" />
          <div className="pure-u-2-3 pure-u">
            <h2>Hi I'm Betty!</h2>
          </div>
        </div>
        <div className="pure-g">
          <FlowerWrapper className="pure-u-1-3 pure-u">
            <PictureWithFlowers imageToWrap={bettyImage} rightFlower={
              <StaticImage alt="Lupine flower illustration" src="../images/illustrations/Lupine-color-large.png" height={500} />} omitLeftFlower={true} />
          </FlowerWrapper>
          <div className="pure-u-2-3 pure-u">
            <p>
              And I'm not just your elopement photographer. I'm more like the travel guide for your wedding experience! I'm here to help with location planning, weather tracking, timelines, connecting you with vendor referrals etc.
            </p>
            <p>
              By booking your wedding photography with me, you are also supporting:
            </p>
            <ul>
              <li>Financially giving to help others have access to the outdoors. A percentage of every booking goes to <a href="https://www.instagram.com/outdoorsforall/">@outdoorsforall</a>, and <a href="https://www.instagram.com/youngwomenempowered/">@youngwomenempowered</a>.</li>
              <li>
                Location planning that is intentional and respects the land we are on. I follow <a href="https://lnt.org/">Leave No Trace Principals</a> to minimize my impact in wilderness areas.
              </li>
              <li>Contributing to a local Washington woman owned small business.</li>
              <li>LGBTQ-friendly and affirming wedding vendor.</li>
            </ul>
            <p>
              You are doing MORE than booking a vendor for your wedding day, your are ethically choosing a photographer that will be able to continue to educate, give, and serve others in the wedding industry, and outdoor community.
            </p>
            <p className="text-center">
              <Link className="pure-button pure-button-primary full-touch-button" to={paths.contact.url}>Let's Work Together!</Link>
            </p>
          </div>
        </div>
      </HiImBetty>
      <FeaturedIn>
        <FeaturedInHeader>
          <WingedContent content={<h2>Featured In</h2>} rightWingContent={<StaticImage src="../images/illustrations/Champagne2-color-medium.png" alt="Champagne Glass" height={120} style={{ position: "absolute" }} />} />
        </FeaturedInHeader>
        <ListWrapper>
          <ul>
            <li>Seattle Bride Magazine</li>
            <li>The Knot</li>
            <li>Style Me Pretty</li>
            <li>June Bug Weddings</li>
            <li>Wedding Chicks</li>
            <li>Wedding Wire Couple's Choice Awards</li>
            <li>Intimate Weddings</li>
            <li>Bridal Musings</li>
            <li>Trendy Bride Magazine</li>
            <li>Rustic Wedding Chic</li>
            <li>The Perfect Palette</li>
            <li>Aisle Perfect</li>
            <li>Apple Brides</li>
            <li>Artfully Wed</li>
            <li>PNW Wedding</li>
          </ul>
        </ListWrapper>
        <p className="text-center">
          <Link className="pure-button pure-button-primary full-touch-button" to={paths.portfolio.url}>View portfolio</Link>
        </p>
      </FeaturedIn>
      <MoreAboutMe>
        <h2>A little bit more about me...</h2>
        <Bird>
          <StaticImage alt="Towhee Bird illustration" src="../images/illustrations/Towhee-color-medium.png" width={122} />
        </Bird>
        <p>
          I'm a wife, mama, feminist, visual storyteller, mixology hobbyist, and adventure seeker. I've been married to my best friend and husband, Matt, for 12+ years, our family includes our boys Wilder and Emery, and our corgi Indie. I'm always planning our next hike, or trip. I love Seattle and the PNW, it is my home. I've always felt a sacred connection when creating art and photography in the outdoors, and that's the emphasis you will see in my wedding and elopement photography. I want to experience these places with others, and create where I am most inspired. When you book me as your photographer, I'm also here to answer all your "ask a local" questions. I love referring amazing elopement vendors, giving tips for fun hikes to go on, and recommending my favorite place to grab a cocktail or a coffee.
        </p>
        <MoreAboutMeImage className="text-center">
          <StaticImage alt="More images about Betty from Photography by Betty Elaine" src="../images/theme/Seattle-Elopement-Photographer.jpg" width={1200} />
        </MoreAboutMeImage>
        <p className="text-center">
          <Link className="pure-button pure-button-primary full-touch-button" to={paths.pricing.url}>View Wedding Collections</Link>
        </p>
      </MoreAboutMe>
      <FunFacts>
        <header>
          <WingedContent content={<h2>Fun Facts!</h2>} rightWingContent={<StaticImage alt="Seattle Pinecone illustration" src="../images/illustrations/Pinecone-color-medium.png" height={300} style={{ position: "absolute" }} />} />
        </header>
        <ul>
          <li>I'm an ordained officiant and can sign your marriage license.</li>
          <li>I am an extrovert. I use this part of my personality to connect with people, and to connect others. This really helps if you're nervous about being photographed. I'm ready to interact with you and make sure everyone is having a good time.</li>
          <li>I shoot with a medium format film camera for personal use, and sometimes enjoy snapping a few frames with it during portrait sessions. Getting a roll of film developed is so exciting!</li>
          <li>On weekends I am not photographing a wedding, enjoy traveling, hiking, or camping. And when I'm feeling more like being a homebody, I make a fantastic quiche, find time to sneak away from the kids to go antique shopping, and if I'm not immersed in a book, I like to attempt the same embroidery project I've been working on since last Christmas. </li>
          <li>I grew up in Hertfordshire, England. It rains there too.</li>
          <li>I am madly in love with my Husband, Matt. He is my best friend and we've been together for 12+ years. We like to sing and play music together, experiment with Italian cooking, or order pizza, and do DIY projects on our house. Being married to him is my favorite adventure.</li>
          <li>I'm a Mama to my sons Wilder, Emery, and my "baby dog" Indie. They are all bold, adventurous, and love the water.</li>
          <li>One of my hobbies is mixology. Gin is a staple on my bar cart. My husband loves gardening. He grows herbs and edible flowers I can use all summer to flavor and garnish drinks.</li>
          <li>I started photography to document all the places I traveled, but somewhere in that journey I started photographing love stories.</li>
          <li>Being a Wedding Photographer is a joy in my life. It is an honor to be a part of couple's weddings</li>
        </ul>
      </FunFacts>
      <CenterImageWrapper>
        <StaticImage alt="National Park Elopement Photography in Washington - Wedding Ideas" src="../images/theme/National-Park-Elopement-Photographer-Washington-Wedding-Ideas.jpg" width={1200} />
      </CenterImageWrapper>
      <MyPhilosophy>
        <h2>My Philosophy</h2>
        <h3>I am a curator of stories, and I tell those stories in photos</h3>
        <p>Your love story is important to me, and I want to tell that story with your wedding photography.</p>
        <p>I photograph weddings because I truly believe in marriage. As a wedding and elopement photographer, I'm honored to document such a significant day in your relationship, however grand or intimate the celebration is. I think wedding vows matter, and choosing to live them out everyday is extremely purposeful. As each day, and year go by in your relationship, you've achieved a precious and beautiful accomplishment living out your commitment together.</p>
        <p>My photography style is intimate, romantic, and editorial. Behind my camera I feel a sense of freedom to explore, create scenes, capture details, and really see the couple's vision for their wedding. I aim to encapsulate beauty and joy in each photograph. My clients are adventurous. They are the type of people who see the fun in getting their wedding dress and shoes a little dirty, will climb trees and hike with me, they value capturing moments epically more than staying picture perfect.</p>
        <p>On your wedding day I won't just photograph you, but I'll be there to document your friends, family, and all the details of your day. I might cry during your vows, I might whisk you two away so you can have a few minutes alone, I want your wedding day photography to be a tangible representation of your legacy together so you can remember and continue to share your wedding day over the years, and as your family grows.</p>
        <p className="text-center">
          <StaticImage alt="Washington Mountain Illustration" src="../images/illustrations/Mountain-color-medium.png" width={800} style={{ "textAlign": "center" }} />
        </p>
        <p className="text-center" style={{ 'textAlign': 'center' }}>
          <Link className="pure-button pure-button-primary full-touch-button" to={paths.contact.url}>Contact Me</Link>
        </p>
      </MyPhilosophy>
    </Container>
  )
}

export default About
