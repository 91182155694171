import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
const defaultFlowerImgUrl = "../../images/illustrations/Indian-Paintbrush-color-large.png"

const FlowerLeft = styled.div``;
const FlowerRight = styled.div``;

const Picture = styled.div`
position: relative;
z-index: 15;
text-align: center;

> div {
  display: inline-block;
  position: relative;
  width: 320px;
}

${FlowerRight}, ${FlowerLeft} {
  position: absolute;
  top: 0;
}

${FlowerRight} {
  right: -150px;
  z-index: 20;
}

${FlowerLeft} {
  z-index: 10;
  left: -150px;
}
`;

const DecoratedImage = styled.div`
position: relative;
display: inline-block;
z-index: 15;
`;

const PictureWithFlowers = ({ imageToWrap, leftFlower, rightFlower, omitLeftFlower, className }) => {
  const renderLeftFlower = function () {
    if (omitLeftFlower) {
      return;
    }
    // Set default only if !omitLeftFlower\
    if (!leftFlower) {
      leftFlower = <StaticImage alt="Flower illustration" src={defaultFlowerImgUrl} height={500} />
    }
    return (
      <FlowerLeft>
        {leftFlower}
      </FlowerLeft>
    )
  }

  const renderRightFlower = function () {
    if (!rightFlower) {
      rightFlower = <StaticImage alt="Flower illustration" src={defaultFlowerImgUrl} height={500} imgStyle={{
        height: "100%",
        WebkitTransform: "scaleX(-1)",
        transform: "scaleX(-1)"
      }} />
    }

    return (
      <FlowerRight>
        {rightFlower}
      </FlowerRight>
    )
  }

  return (
    <Picture className={className}>
      <div>
        <DecoratedImage>
          {imageToWrap}
        </DecoratedImage>
        {renderLeftFlower()}
        {renderRightFlower()}
      </div>
    </Picture>
  )
};

export default PictureWithFlowers