import React from "react"
import styled from "styled-components"

const Container = styled.div`
position: relative;
z-index: 15;
text-align: center;
display: inline-block;

.leftWing, .rightWing {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.leftWing {
  left: 0;
  z-index: 10;
}

.rightWing {
  right: 0;
  z-index: 20;
}
`;

const ContentWrapper = styled.div`
position: relative;
display: inline-block;
z-index: 15;
`;


const Wing = ({ content, className }) => {
  if (!content) {
    return;
  }
  return (
    <div className={className}>{content}</div>
  )
};

const WingedContent = ({ content, leftWingContent, rightWingContent }) => {
  return (
    <Container className="wingedContainer">
      <ContentWrapper>
        {content}
      </ContentWrapper>
      {Wing({ content: leftWingContent, className: "leftWing" })}
      {Wing({ content: rightWingContent, className: "rightWing" })}
    </Container>
  )
};

export default WingedContent